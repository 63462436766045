(function(){
    angular
        .module('app')
        .directive('ngSelect2', select2);

        select2.$inject = ['$timeout'];
        function select2($timeout){
            return {
              require: 'ngModel',
              scope:{
                ngModel:"=",
                options:"="
              },
            link: function (scope, element, $attrs, ngModel) {
                return $timeout(function () {
                  var options = {};
                  if(scope.options){
                    options = scope.options;
                  }
                  //console.log(scope.options);
                  var valorrel = "";
                    var value = $attrs.value;
                    var $element = $(element);
                    var name = $element.attr("id");
                    var valuesArray = [];
        
                    $element.select2(options);
                    $element.on("select2:select", function (evt) {
                        var elementVar = evt.params.data.element;
                        var $elementVar = $(elementVar);
                        ngModel.$setViewValue($element.val());
                        console.log(ngModel);
                        /*alert($element.val());
                        $elementVar.detach();
                        $(this).append($elementVar);
                        $(this).trigger("change");*/
                      });

                    function cambiando(){
                      //console.log("CAMBIANDO");
                      //console.log($element);
                      //console.log(scope.ngModel);
                      if(scope.ngModel==""){
                        $element.trigger('change.select2');
                      }
                    }

                    scope.$watch('ngModel', cambiando, true);

                });
            }
          }
      }
})();